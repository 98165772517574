.card {
  background-color: var(--background-light);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin: 2rem auto;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border: 1px solid var(--background-dark);
}

.card:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.card-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}

.text {
  justify-self: start;
  overflow: auto;
  color: var(--text-dark);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 15px;
}

.image-grid img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

@media (max-width: 800px) {
  .card {
    padding: 20px;
  }

  .text {
    min-height: 70px;
  }
}

@media (max-width: 480px) {
  .card {
    padding: 15px;
  }
}
