.about-image-container {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  transition: all 0.3s ease;
  max-width: 100%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  border: 4px solid var(--background-light);
  margin-bottom: -30px;
  position: relative;
  z-index: 2;
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
  box-sizing: border-box;
}

.team-member-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.about-page-main {
  padding: 20px 0;
  width: 100%;
  overflow-x: hidden;
}

.about-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
}

.about-grid {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.card {
  flex-grow: 1;
  padding: 25px;
  padding-top: 45px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  background-color: var(--background-light);
  position: relative;
  z-index: 1;
  border: 1px solid var(--background-dark);
}

.card h2 {
  margin-top: 0;
  font-size: 1.8rem;
  margin-bottom: 5px;
  color: var(--secondary);
  font-weight: 600;
}

.member-title {
  font-size: 1.2rem;
  color: var(--text-muted);
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 500;
  position: relative;
  padding-bottom: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.member-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background-color: var(--accent);
}

.card p {
  font-size: 1rem;
  line-height: 1.6;
  margin: 0;
  color: var(--text-dark);
}

/* Tablet devices */
@media (max-width: 1024px) {
  .about-image-container {
    width: 320px;
    height: 320px;
    margin-bottom: -25px;
  }

  .team-title {
    font-size: 2.2rem;
    margin-bottom: 35px;
  }

  .card {
    padding-top: 40px;
  }

  .card h2 {
    font-size: 1.6rem;
  }

  .member-title {
    font-size: 1.1rem;
  }
}

/* Mobile devices */
@media (max-width: 800px) {
  .about-image-container {
    width: 280px;
    height: 280px;
    margin-bottom: -20px;
  }

  .team-title {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .about-container {
    margin-left: 0;
    margin-right: 0;
    padding: 0 10px;
  }

  .about-page-container {
    padding: 0 10px;
  }

  .about-grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .card {
    padding: 20px;
    padding-top: 35px;
  }

  .card h2 {
    font-size: 1.4rem;
  }

  .member-title {
    font-size: 1rem;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }

  .card p {
    font-size: 0.9rem;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .about-image-container {
    width: 240px;
    height: 240px;
    margin-bottom: -15px;
  }

  .team-title {
    font-size: 1.8rem;
    margin-bottom: 25px;
  }

  .team-title:after {
    width: 50px;
    height: 2px;
  }

  .about-page-container {
    padding: 0 5px;
  }

  .card {
    padding: 15px;
    padding-top: 30px;
  }

  .card h2 {
    font-size: 1.2rem;
  }

  .member-title {
    font-size: 0.9rem;
    margin-bottom: 12px;
    padding-bottom: 8px;
  }

  .member-title:after {
    width: 30px;
  }

  .card p {
    font-size: 0.85rem;
  }
}
