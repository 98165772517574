.round-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin: 10px;
  font-size: 1rem;
  cursor: pointer;
}

.round-button:hover {
  opacity: 0.8;
}
