.card-project {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
  padding: 30px;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.card-header {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.text-content {
  flex: 1;
}

.text-content h2 {
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
  color: #2d3748;
}

.text-content p {
  font-size: 1rem;
  line-height: 1.6;
  color: #4a5568;
  margin-bottom: 1.5rem;
}

.tech-icons {
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.tech-icons h1 {
  font-size: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img img {
  max-width: 150px;
  max-height: 150px;
  object-fit: contain;
}

.rounded-image {
  border-radius: 50%;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.08);
  background-color: white;
  padding: 8px;
}

/* Default button style for non-grid usage */
.card-button {
  display: inline-block;
  padding: 0.85rem 1.75rem;
  background-color: #2d3748;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  margin-top: 1rem;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.card-button:hover {
  background-color: #4a5568;
  transform: translateY(-2px);
}

@media (max-width: 800px) {
  .card-project {
    min-height: auto;
    padding: 25px;
    margin: 1.5rem auto;
  }
}
