/* Standard Title Colors */
h1,
h2,
h3 {
  color: #2d3748;
}

/* Unified Section Title Styling */
.section-title {
  text-align: center;
  margin: 4rem 0 2rem 0;
  color: #2d3748;
  font-size: 2.75rem;
  font-weight: 700;
  position: relative;
  display: inline-block;
  letter-spacing: 1px;
}

.section-title:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: var(--accent);
}

.home-image-container {
  width: 300px;
}

.home-card-image-container {
  width: 100%;
}

.home-title-image-container {
  width: 70%;
  border: 4px solid var(--background-light);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.home-title {
  margin-top: 100px;
  color: var(--secondary);
}

.home-title h1 {
  position: relative;
  display: inline-block;
  color: #2d3748;
}

.home-title h1:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 3px;
  background-color: var(--accent);
}

.home-center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-grid-container-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-left: 60px;
  margin-right: 60px;
}

.home-grid-container-inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.text {
  color: #4a5568;
}

.hidden {
  display: visible;
}

.coder-image {
  margin-left: 20%;
  margin-top: 200px;
}

/* Card Button Styles */
.card-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--primary);
  color: var(--text-light);
  text-decoration: none;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.card-button:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.card-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-button-arrow {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.card-button:hover .card-button-arrow {
  transform: translateX(4px);
}

@media (max-width: 800px) {
  .homepage-projects {
    padding: 0 15px 20px;
    margin-top: 5px;
  }

  .home-grid-container-header {
    grid-template-columns: 1fr;
    margin-left: 10px;
    margin-right: 10px;
  }
  .home-grid-container-inner {
    grid-template-columns: 1fr;
    margin-left: 10px;
    margin-right: 10px;
  }
  .home-title {
    margin-top: 50px;
  }
  .hidden {
    display: none;
  }
  .coder-image {
    margin-top: 50px;
    justify-content: center;
    align-items: center;
  }
  .home-card-image-container {
    width: 50%;
  }

  .card-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  main {
    padding-bottom: 80px; /* Add extra padding at the bottom of main content */
  }
}

@media (max-width: 480px) {
  .card-button {
    padding: 6px 12px;
    font-size: 0.85rem;
  }

  .card-button-arrow {
    width: 14px;
    height: 14px;
    margin-left: 6px;
  }
}

/* Projects section on homepage */
.homepage-projects {
  padding: 0 60px 30px;
  gap: 30px;
  margin-top: 10px;
}

.homepage-projects .card-project {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
}

.homepage-projects .tech-icons {
  display: flex;
  gap: 12px;
  margin-bottom: 15px;
  font-size: 1.2rem;
  color: #4a5568;
}

.homepage-projects .tech-icons span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: #f7fafc;
  border-radius: 50%;
  padding: 8px;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.homepage-projects .tech-icons span:hover {
  background-color: #edf2f7;
  transform: scale(1.1);
}

/* Add responsive styles for section-title */
@media (max-width: 1024px) {
  .section-title {
    font-size: 2.25rem;
    margin: 3.5rem 0 1.75rem 0;
  }

  .section-title:after {
    width: 50px;
    bottom: -8px;
  }
}

@media (max-width: 768px) {
  .section-title {
    font-size: 2rem;
    margin: 3rem 0 1.5rem 0;
  }

  .section-title:after {
    width: 45px;
    height: 2.5px;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 1.75rem;
    margin: 2.5rem 0 1.25rem 0;
  }

  .section-title:after {
    width: 40px;
    height: 2px;
    bottom: -6px;
  }
}

/* Responsive styles for the home section */
@media screen and (max-width: 768px) {
  /* Styles for mobile view */
}

/* Special container for section titles */
.section-title-container {
  margin-bottom: 0;
  padding-bottom: 0;
}
