.footer-box {
  width: 100%;
  padding-top: 2em;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  bottom: 0px;
}

.container {
  display: flex;
  justify-content: center;
  margin: auto;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 50%;
}

.footer-text {
  margin: 10px 0px 0px 0px;
  width: 200px;
}

/* Copyright styling */
.copyright {
  font-size: 0.9rem;
  font-weight: normal;
  opacity: 0.8;
}

.center-text {
  text-align: center;
}

.leftalign-text {
  text-align: left;
}

.column-right {
  order: 2;
  align-items: flex-start;
}

h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  height: 40px;
}

p {
  margin: 0;
}

@media screen and (max-width: 1100px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .column {
    margin: 10px 0;
  }

  .column {
    margin: 10px 0;
    order: 0;
  }

  .column-right {
    order: 1;
  }
}
