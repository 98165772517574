.student-grid {
  display: flex;
  justify-content: space-between;
}

.student-description {
  flex: 1;
}

.student-image-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  margin-top: 20px;
}

.student-info {
  text-align: center;
  flex: 1;
}

.student-image {
  width: 150px;
  height: 150px;
}

.rounded-image {
  border-radius: 50%;
}
.center-content {
  text-align: center;
}

/* Mobile layout */
@media (max-width: 768px) {
  .student-grid {
    flex-direction: column;
  }

  .student-image-section {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .student-image {
    width: 150px;
    height: 150px;
  }
}
