.button {
  font-family: "Quicksand", sans-serif;
  background-color: #007bff;
  color: #fff;
  border-radius: 10px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  margin: auto;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}

.button:hover {
  background-color: #0059b8;
}
