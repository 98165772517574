@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

:root {
  /* Main Color Palette */
  --primary: #2563eb; /* Vibrant blue - primary actions, buttons */
  --secondary: #1e293b; /* Dark blue-gray - headers, text */
  --accent: #f97316; /* Orange - accents, highlights */
  --background: #f8fafc; /* Light gray - backgrounds */

  /* Shades and tints */
  --primary-dark: #1d4ed8;
  --primary-light: #3b82f6;
  --secondary-dark: #0f172a;
  --secondary-light: #334155;
  --accent-dark: #ea580c;
  --accent-light: #fb923c;
  --background-dark: #e2e8f0;
  --background-light: #ffffff;

  /* Text colors */
  --text-dark: #1e293b;
  --text-light: #f8fafc;
  --text-muted: #64748b;
}

.App {
  font-family: "Quicksand", sans-serif;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    180deg,
    var(--background-light) 0%,
    var(--background-dark) 100%
  );
  width: 100%;
  box-sizing: border-box;
}

.App main {
  width: 100%;
  box-sizing: border-box;
}

.header-image-container {
  width: 300px;
}

.header-image-container:hover {
  opacity: 0.8;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

/* Default grid classes (not used in portfolio grid) */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  padding: 0 40px;
  box-sizing: border-box;
}

.grid-container2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  padding: 0 40px;
  box-sizing: border-box;
}

.text {
  justify-self: start;
  overflow: auto;
}

/* Global image styles */
.rounded-image {
  border-radius: 50%;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  background-color: white;
  margin-bottom: 20px;
  max-width: 100%;
}

@media (max-width: 1300px) {
  .grid-container {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
}

@media (max-width: 800px) {
  .grid-container2 {
    grid-template-columns: 1fr;
    padding: 0 20px;
    grid-gap: 20px;
  }

  .header-image-container {
    width: 150px;
  }

  .text {
    min-height: 70px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
